module.exports = {
    index : [{
        text : `勐海是世界茶树原产地的中心地带，也是具有1700多年历史的古老茶区，是世界公认的普洱茶发祥地之一，是著名的 “茶树王之乡”，境内曾经存活1700多年的野生型 “古茶树王”、
        800多年的栽培型“古茶树王”及保存至今的3200公顷百年以上栽培型古茶树及多个大面积野生型大茶树居群就是最好的证明。其中，栽培型古茶树主要属于普洱茶种，在县内各个乡镇均有分布；
        也有一部分苦茶变种，主要分布在布朗山乡。野生型大茶树主要属于大理茶种，主要分布在西定、勐宋、格朗和等乡镇，分布面积达8000多公顷。`
    },{
        text : `勐海茶树品种资源十分丰富，其中，勐海大叶茶是云南大叶茶群体类型之一，在分类上属于普洱茶种，1985年被认定为第一批国家级有性系优良品种，是我国最优良的地方茶树品种之一，
        其叶片肥厚、叶面隆起，芽叶肥壮、茸毛多，发芽早、育芽能力强，生长发芽期一年长达10个月左右，每年2月中旬即可开采至11月中上旬，且持嫩性强，品质优、产量高，用其鲜叶原料加工制作的普洱茶、红茶等茶类产品，品质在全国是最佳的。`
    },{
        text : `勐海大叶茶还具有内合物质丰富、保健功效明显的特点，其水浸出物含量达48.75%，茶多酚含量平均高达32.96%，儿茶素总量达17.9%，咖啡碱含量达5%。以勐海大叶茶制成的普洱茶，
        具有滋味醇厚、陈香独特的品质特征，并在较长的时间内具有越陈越香、后韵悠远的特点，并具有降血脂、预防心脑血管疾病、调节免疫功能、延缓衰老、抗辐射、抗肿瘤、抗病毒等方面的保健价值。`
    }]
}