<template>
    <div>
        <z-header :imgShowPro="`false`" :curActive="0"></z-header>
        <div class="title">
            <span>勐海普洱茶介绍</span>
            <p>MENGHAI PU'ER TEA INTRODUCTION</p>
        </div>
        <div class="content">
            <p v-for="(item,index) of contentData" :key="index">{{item.text}}</p>
        </div>
    </div>
</template>
<script>
import zHeader from '../components/header.vue'
import infoData from './data'
export default {
    components:{zHeader},
    data(){
        return{
            contentData:[]
        }
    },
    created(){
        this.contentData = infoData.index
    }
}
</script>
<style scoped>
    .title{
        /* width: 100%; */
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 20px;
        box-sizing: border-box;
        border-bottom: 2px solid #eee;
    }
    .title>span{
        font-size: 18px;
        border-bottom: 14px;
        color: #333;
    }
    .title>p{
        font-size: 9px;
        color: #666;
    }
    .content{
        padding: 22px 20px 50px;
        box-sizing: border-box;
        font-size: 14px;
        color: #666;
        line-height: 28px;
    }
    .content>p{
        margin-bottom: 28px;
    }
</style>